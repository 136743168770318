"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFees = exports.calcNetworkFeeCryptoBaseUnit = void 0;
const bignumber_1 = require("./bignumber/bignumber");
const calcNetworkFeeCryptoBaseUnit = (args) => {
    const { supportsEIP1559, gasLimit, gasPrice, l1GasLimit, l1GasPrice, maxFeePerGas, maxPriorityFeePerGas, } = args;
    // l1 fee if exists or 0
    const l1Fee = (0, bignumber_1.bnOrZero)(l1GasPrice).times((0, bignumber_1.bnOrZero)(l1GasLimit));
    // eip1559 fees
    if (supportsEIP1559 && maxFeePerGas && maxPriorityFeePerGas) {
        return (0, bignumber_1.bn)(gasLimit).times(maxFeePerGas).plus(l1Fee).toFixed(0);
    }
    // legacy fees
    return (0, bignumber_1.bn)(gasLimit).times(gasPrice).plus(l1Fee).toFixed(0);
};
exports.calcNetworkFeeCryptoBaseUnit = calcNetworkFeeCryptoBaseUnit;
const getFees = async (args) => {
    const { adapter, data, to, value, from, supportsEIP1559 } = args;
    const { average: { chainSpecific: feeData }, } = await adapter.getFeeData({ to, value, chainSpecific: { from, data } });
    const networkFeeCryptoBaseUnit = (0, exports.calcNetworkFeeCryptoBaseUnit)({
        ...feeData,
        supportsEIP1559,
    });
    const { gasLimit, gasPrice, maxFeePerGas, maxPriorityFeePerGas } = feeData;
    if (supportsEIP1559 && maxFeePerGas && maxPriorityFeePerGas) {
        return { networkFeeCryptoBaseUnit, gasLimit, maxFeePerGas, maxPriorityFeePerGas };
    }
    return { networkFeeCryptoBaseUnit, gasLimit, gasPrice };
};
exports.getFees = getFees;
