"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COW_SWAP_SUPPORTED_CHAIN_IDS = exports.SUPPORTED_CHAIN_IDS = exports.COW_SWAP_NATIVE_ASSET_MARKER_ADDRESS = exports.ERC20_TOKEN_BALANCE = exports.SIGNING_SCHEME = exports.ORDER_KIND_SELL = exports.COW_SWAP_SETTLEMENT_ADDRESS = exports.COW_SWAP_VAULT_RELAYER_ADDRESS = exports.DEFAULT_ADDRESS = void 0;
const types_1 = require("@shapeshiftoss/types");
const viem_1 = require("viem");
exports.DEFAULT_ADDRESS = viem_1.zeroAddress;
exports.COW_SWAP_VAULT_RELAYER_ADDRESS = '0xc92e8bdf79f0507f65a392b0ab4667716bfe0110';
exports.COW_SWAP_SETTLEMENT_ADDRESS = '0x9008D19f58AAbD9eD0D60971565AA8510560ab41';
exports.ORDER_KIND_SELL = 'sell';
exports.SIGNING_SCHEME = 'eip712';
exports.ERC20_TOKEN_BALANCE = 'erc20';
// Address used by CowSwap to buy ETH
// See https://github.com/gnosis/gp-v2-contracts/commit/821b5a8da213297b0f7f1d8b17c893c5627020af#diff-12bbbe13cd5cf42d639e34a39d8795021ba40d3ee1e1a8282df652eb161a11d6R13
exports.COW_SWAP_NATIVE_ASSET_MARKER_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
exports.SUPPORTED_CHAIN_IDS = [
    types_1.KnownChainIds.EthereumMainnet,
    types_1.KnownChainIds.GnosisMainnet,
    types_1.KnownChainIds.ArbitrumMainnet,
];
exports.COW_SWAP_SUPPORTED_CHAIN_IDS = {
    sell: exports.SUPPORTED_CHAIN_IDS,
    buy: exports.SUPPORTED_CHAIN_IDS,
};
