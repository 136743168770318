"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThorTxInfo = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const thorchain_utils_1 = require("../../../../thorchain-utils");
const helpers_1 = require("../../../utils/helpers/helpers");
const getThorTxInfo = async ({ sellAsset, sellAmountCryptoBaseUnit, memo, expiry, config, }) => {
    const daemonUrl = config.REACT_APP_THORCHAIN_NODE_URL;
    const { assetReference } = (0, caip_1.fromAssetId)(sellAsset.assetId);
    const maybeInboundAddress = await (0, thorchain_utils_1.getInboundAddressDataForChain)(daemonUrl, sellAsset.assetId);
    if (maybeInboundAddress.isErr())
        throw maybeInboundAddress.unwrapErr();
    const inboundAddress = maybeInboundAddress.unwrap();
    const router = inboundAddress.router;
    const vault = inboundAddress.address;
    if (!router) {
        throw Error(`No router found for ${sellAsset.assetId} at inbound address ${inboundAddress}`);
    }
    const data = (0, thorchain_utils_1.depositWithExpiry)({
        vault,
        asset: (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId)
            ? '0x0000000000000000000000000000000000000000'
            : assetReference,
        amount: sellAmountCryptoBaseUnit,
        memo,
        expiry,
    });
    return { data, router, vault };
};
exports.getThorTxInfo = getThorTxInfo;
