"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cowApi = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const unchained_client_1 = require("@shapeshiftoss/unchained-client");
const utils_1 = require("@shapeshiftoss/utils");
const uuid_1 = require("uuid");
const constants_1 = require("../../constants");
const types_1 = require("../../types");
const utils_2 = require("../../utils");
const helpers_1 = require("../utils/helpers/helpers");
const getCowSwapTradeQuote_1 = require("./getCowSwapTradeQuote/getCowSwapTradeQuote");
const constants_2 = require("./utils/constants");
const cowService_1 = require("./utils/cowService");
const helpers_2 = require("./utils/helpers/helpers");
const tradeQuoteMetadata = new Map();
exports.cowApi = {
    getTradeQuote: async (input, { config }) => {
        const tradeQuoteResult = await (0, getCowSwapTradeQuote_1.getCowSwapTradeQuote)(input, config);
        return tradeQuoteResult.map(tradeQuote => {
            // A quote always has a first step
            const firstStep = (0, utils_2.getHopByIndex)(tradeQuote, 0);
            const id = (0, uuid_1.v4)();
            tradeQuoteMetadata.set(id, { chainId: firstStep.sellAsset.chainId });
            return [tradeQuote];
        });
    },
    getUnsignedEvmMessage: async ({ from, tradeQuote, stepIndex, chainId, config, }) => {
        const hop = (0, utils_2.getHopByIndex)(tradeQuote, stepIndex);
        if (!hop)
            throw new Error(`No hop found for stepIndex ${stepIndex}`);
        const { buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit } = hop;
        const { receiveAddress, slippageTolerancePercentageDecimal = (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.CowSwap), } = tradeQuote;
        const buyTokenAddress = !(0, helpers_1.isNativeEvmAsset)(buyAsset.assetId)
            ? (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference
            : constants_2.COW_SWAP_NATIVE_ASSET_MARKER_ADDRESS;
        const maybeNetwork = (0, helpers_2.getCowswapNetwork)(sellAsset.chainId);
        if (maybeNetwork.isErr())
            throw maybeNetwork.unwrapErr();
        const network = maybeNetwork.unwrap();
        const affiliateAppDataFragment = (0, helpers_2.getAffiliateAppDataFragmentByChainId)({
            affiliateBps: tradeQuote.affiliateBps,
            chainId: sellAsset.chainId,
        });
        const { appData, appDataHash } = await (0, helpers_2.getFullAppData)(slippageTolerancePercentageDecimal, affiliateAppDataFragment);
        // https://api.cow.fi/docs/#/default/post_api_v1_quote
        const maybeQuoteResponse = await cowService_1.cowService.post(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/quote/`, {
            sellToken: (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
            buyToken: buyTokenAddress,
            receiver: receiveAddress,
            validTo: (0, helpers_2.getNowPlusThirtyMinutesTimestamp)(),
            appData,
            appDataHash,
            partiallyFillable: false,
            from,
            kind: constants_2.ORDER_KIND_SELL,
            sellAmountBeforeFee: sellAmountIncludingProtocolFeesCryptoBaseUnit,
        });
        if (maybeQuoteResponse.isErr())
            throw maybeQuoteResponse.unwrapErr();
        const { data: cowSwapQuoteResponse } = maybeQuoteResponse.unwrap();
        const { id, quote } = cowSwapQuoteResponse;
        // Note: While CowSwap returns us a quote, and we have slippageBips in the appData, this isn't enough.
        // For the slippage actually to be enforced, the final message to be signed needs to have slippage deducted.
        // Failure to do so means orders may take forever to be filled, or never be filled at all.
        const quoteBuyAmount = quote.buyAmount;
        // Remove affiliate fees off the buyAmount to get the amount after affiliate fees, but before slippage bips
        const buyAmountAfterAffiliateFeesCryptoBaseUnit = (0, helpers_2.deductAffiliateFeesFromAmount)({
            amount: quoteBuyAmount,
            affiliateBps: tradeQuote.affiliateBps,
        });
        const buyAmountAfterAffiliateFeesAndSlippageCryptoBaseUnit = (0, helpers_2.deductSlippageFromAmount)({
            amount: buyAmountAfterAffiliateFeesCryptoBaseUnit,
            slippageTolerancePercentageDecimal,
        }).toFixed(0);
        // CoW API and flow is weird - same idea as the mutation above, we need to incorporate protocol fees into the order
        // This was previously working as-is with fees being deducted from the sell amount at protocol-level, but we now we need to add them into the order
        // In other words, this means what was previously CoW being "feeless" as far as we're concerned
        // i.e no additional fees to account for when doing balance checks, no longer holds true
        //
        // This also makes CoW the first and currently *only* swapper where max token swaps aren't full balance
        const sellAmountPlusProtocolFees = (0, utils_1.bn)(quote.sellAmount).plus(quote.feeAmount);
        const orderToSign = {
            ...quote,
            // Another mutation from the original quote to go around the fact that CoW API flow is weird
            // they return us a quote with fees, but we have to zero them out when sending the order
            feeAmount: '0',
            buyAmount: buyAmountAfterAffiliateFeesAndSlippageCryptoBaseUnit,
            sellAmount: sellAmountPlusProtocolFees.toFixed(0),
            // from,
            sellTokenBalance: constants_2.ERC20_TOKEN_BALANCE,
            buyTokenBalance: constants_2.ERC20_TOKEN_BALANCE,
            quoteId: id,
            appDataHash,
            signingScheme: constants_2.SIGNING_SCHEME,
        };
        return { chainId, orderToSign };
    },
    checkTradeStatus: async ({ txHash, // TODO: this is not a tx hash, its an ID
    chainId, assertGetEvmChainAdapter, config, }) => {
        const maybeSafeTransactionStatus = await (0, utils_2.checkSafeTransactionStatus)({
            txHash,
            chainId,
            assertGetEvmChainAdapter,
        });
        if (maybeSafeTransactionStatus)
            return maybeSafeTransactionStatus;
        const maybeNetwork = (0, helpers_2.getCowswapNetwork)(chainId);
        if (maybeNetwork.isErr())
            throw maybeNetwork.unwrapErr();
        const network = maybeNetwork.unwrap();
        // with cow we aren't able to get the tx hash until it's already completed, so we must use the
        // order uid to fetch the trades and use their existence as indicating "complete"
        // https://docs.cow.fi/tutorials/how-to-submit-orders-via-the-api/6.-checking-order-status
        const maybeTradesResponse = await cowService_1.cowService.get(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/trades`, { params: { orderUid: txHash } });
        if (maybeTradesResponse.isErr())
            throw maybeTradesResponse.unwrapErr();
        const { data: trades } = maybeTradesResponse.unwrap();
        const buyTxHash = trades[0]?.txHash;
        if (!buyTxHash)
            return (0, utils_2.createDefaultStatusResponse)(undefined);
        const maybeGetOrdersResponse = await cowService_1.cowService.get(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/transactions/${buyTxHash}/orders`);
        if (maybeGetOrdersResponse.isErr())
            throw maybeGetOrdersResponse.unwrapErr();
        const { data: [{ status: rawStatus }], } = maybeGetOrdersResponse.unwrap();
        // https://api.cow.fi/docs/#/default/get_api_v1_orders__UID_
        const status = (() => {
            switch (rawStatus) {
                case 'fulfilled':
                    return unchained_client_1.TxStatus.Confirmed;
                case 'presignaturePending':
                case 'open':
                    return unchained_client_1.TxStatus.Pending;
                case 'cancelled':
                case 'expired':
                    return unchained_client_1.TxStatus.Failed;
                default:
                    return unchained_client_1.TxStatus.Unknown;
            }
        })();
        return {
            status,
            buyTxHash,
            message: rawStatus,
        };
    },
};
