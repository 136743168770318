"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getL1ToLongtailQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const constants_2 = require("../constants");
const addL1ToLongtailPartsToMemo_1 = require("./addL1ToLongtailPartsToMemo");
const getBestAggregator_1 = require("./getBestAggregator");
const getL1quote_1 = require("./getL1quote");
const longTailHelpers_1 = require("./longTailHelpers");
// This just uses UniswapV3 to get the longtail quote for now.
const getL1ToLongtailQuote = async (input, deps, streamingInterval) => {
    const { buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit, sellAsset, slippageTolerancePercentageDecimal, } = input;
    const longtailTokensJson = await Promise.resolve().then(() => __importStar(require('../generated/generatedThorLongtailTokens.json')));
    const longtailTokens = longtailTokensJson.default;
    if (!longtailTokens.includes(buyAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[getThorTradeQuote] - Unsupported buyAssetId ${buyAsset.assetId}.`,
            code: types_1.TradeQuoteError.UnsupportedTradePair,
            details: { buyAsset, sellAsset },
        }));
    }
    /*
      We only support L1 -> ethereum longtail swaps for now.
    */
    if (buyAsset.chainId !== caip_1.ethChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[getThorTradeQuote] - Unsupported chainId ${buyAsset.chainId}.`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { buyAssetChainId: buyAsset.chainId },
        }));
    }
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    const sellAssetFeeAssetId = deps.assertGetChainAdapter(sellAssetChainId).getFeeAssetId();
    const sellAssetFeeAsset = sellAssetFeeAssetId ? deps.assetsById[sellAssetFeeAssetId] : undefined;
    const buyAssetFeeAssetId = deps.assertGetChainAdapter(buyAssetChainId).getFeeAssetId();
    const buyAssetFeeAsset = buyAssetFeeAssetId ? deps.assetsById[buyAssetFeeAssetId] : undefined;
    if (!buyAssetFeeAsset) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[getThorTradeQuote] - No native buy asset found for ${buyAssetChainId}.`,
            code: types_1.TradeQuoteError.InternalError,
            details: { buyAssetChainId },
        }));
    }
    if (!sellAssetFeeAsset) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `[getThorTradeQuote] - No native buy asset found for ${sellAssetChainId}.`,
            code: types_1.TradeQuoteError.InternalError,
            details: { sellAssetChainId },
        }));
    }
    const l1Tol1QuoteInput = {
        ...input,
        buyAsset: buyAssetFeeAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit: sellAmountCryptoBaseUnit,
    };
    const maybeThorchainQuotes = await (0, getL1quote_1.getL1quote)(l1Tol1QuoteInput, deps, streamingInterval, longTailHelpers_1.TradeType.L1ToLongTail);
    if (maybeThorchainQuotes.isErr())
        return (0, monads_1.Err)(maybeThorchainQuotes.unwrapErr());
    const thorchainQuotes = maybeThorchainQuotes.unwrap();
    let bestAggregator;
    let quotedAmountOut;
    const promises = await Promise.allSettled(thorchainQuotes.map(async (quote) => {
        // A quote always has a first step
        const onlyStep = (0, utils_2.getHopByIndex)(quote, 0);
        const maybeBestAggregator = await (0, getBestAggregator_1.getBestAggregator)(deps, buyAssetFeeAsset, (0, longTailHelpers_1.getWrappedToken)(buyAssetFeeAsset), (0, longTailHelpers_1.getTokenFromAsset)(buyAsset), onlyStep.buyAmountAfterFeesCryptoBaseUnit);
        if (maybeBestAggregator.isErr())
            return (0, monads_1.Err)(maybeBestAggregator.unwrapErr());
        const unwrappedResult = maybeBestAggregator.unwrap();
        bestAggregator = unwrappedResult.bestAggregator;
        quotedAmountOut = unwrappedResult.quotedAmountOut;
        const updatedMemo = (0, addL1ToLongtailPartsToMemo_1.addL1ToLongtailPartsToMemo)({
            sellAssetChainId,
            aggregator: bestAggregator,
            finalAssetAssetId: buyAsset.assetId,
            finalAssetAmountOut: quotedAmountOut.toString(),
            slippageBps: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Thorchain)).toString(),
            quotedMemo: quote.memo,
            longtailTokens,
        });
        return (0, monads_1.Ok)({
            ...quote,
            memo: updatedMemo,
            aggregator: bestAggregator,
            steps: quote.steps.map(s => ({
                ...s,
                buyAsset,
                buyAmountAfterFeesCryptoBaseUnit: quotedAmountOut.toString(),
                // This is wrong, we should get the get the value before fees or display ETH value received after the thorchain bridge
                buyAmountBeforeFeesCryptoBaseUnit: quotedAmountOut.toString(),
                allowanceContract: constants_2.ALLOWANCE_CONTRACT,
            })),
            isLongtail: true,
            longtailData: {
                L1ToLongtailExpectedAmountOut: quotedAmountOut,
            },
        });
    }));
    if (promises.every(promise => (0, utils_1.isRejected)(promise) || (0, utils_1.isResolvedErr)(promise))) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: '[getThorTradeQuote] - failed to get best aggregator',
            code: types_1.TradeQuoteError.InternalError,
        }));
    }
    const updatedQuotes = promises.filter(utils_1.isFulfilled).map(element => element.value.unwrap());
    return (0, monads_1.Ok)(updatedQuotes);
};
exports.getL1ToLongtailQuote = getL1ToLongtailQuote;
