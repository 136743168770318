"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNetworkFeeCryptoBaseUnit = void 0;
const sdk_1 = require("@lifi/sdk");
const evm_1 = require("@shapeshiftoss/utils/dist/evm");
const viem_1 = require("viem");
const configureLiFi_1 = require("../configureLiFi");
const constants_1 = require("../constants");
const getNetworkFeeCryptoBaseUnit = async ({ chainId, lifiStep, supportsEIP1559, deps, }) => {
    (0, configureLiFi_1.configureLiFi)();
    const adapter = deps.assertGetEvmChainAdapter(chainId);
    const { average } = await adapter.getGasFeeData();
    const l1GasLimit = await (async () => {
        if (!constants_1.L1_FEE_CHAIN_IDS.includes(chainId))
            return;
        const { transactionRequest } = await (0, sdk_1.getStepTransaction)(lifiStep);
        const { data, gasLimit } = transactionRequest ?? {};
        if (!data || !gasLimit) {
            throw new Error('getStepTransaction failed');
        }
        const publicClient = deps.viemClientByChainId[chainId];
        const abi = [
            {
                inputs: [{ internalType: 'bytes', name: '_data', type: 'bytes' }],
                name: 'getL1GasUsed',
                outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                stateMutability: 'view',
                type: 'function',
            },
        ];
        const contract = (0, viem_1.getContract)({
            address: constants_1.L1_GAS_ORACLE_ADDRESS,
            abi,
            client: {
                public: publicClient,
            },
        });
        const l1GasUsed = (await contract.read.getL1GasUsed([data]));
        return l1GasUsed.toString();
    })();
    // aggregate all send gas estimations if available
    const estimatedGasLimit = lifiStep.estimate.gasCosts?.reduce((prev, gasCost) => {
        if (gasCost.type !== 'SEND')
            return prev;
        if (prev === undefined)
            return BigInt(gasCost.estimate);
        return prev + BigInt(gasCost.estimate);
    }, undefined);
    if (!estimatedGasLimit)
        throw new Error('failed to get estimated gas limit');
    const networkFeeCryptoBaseUnit = (0, evm_1.calcNetworkFeeCryptoBaseUnit)({
        ...average,
        supportsEIP1559,
        gasLimit: estimatedGasLimit.toString(),
        l1GasLimit,
    });
    return networkFeeCryptoBaseUnit;
};
exports.getNetworkFeeCryptoBaseUnit = getNetworkFeeCryptoBaseUnit;
