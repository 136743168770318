"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortenedNativeAssetNameByNativeAssetName = exports.selectBestRate = exports.getQuotedAmountOutByPool = exports.getContractDataByPool = exports.generateV3PoolAddressesAcrossFeeRange = exports.feeAmountToContractMap = exports.contractToFeeAmountMap = exports.AggregatorContract = exports.getTradeType = exports.TradeType = exports.getTokenFromAsset = exports.getWrappedToken = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const sdk_core_1 = require("@uniswap/sdk-core");
const v3_sdk_1 = require("@uniswap/v3-sdk");
const viem_1 = require("viem");
const IUniswapV3PoolAbi_1 = require("../getThorTradeQuote/abis/IUniswapV3PoolAbi");
const constants_1 = require("./constants");
const getWrappedToken = (nativeAsset) => {
    switch (nativeAsset.chainId) {
        case caip_1.ethChainId:
            return constants_1.WETH_TOKEN;
        case caip_1.bscChainId:
            return constants_1.WBNB_TOKEN;
        case caip_1.avalancheChainId:
            return constants_1.WAVAX_TOKEN;
        default:
            throw new Error(`getWrappedToken: Unsupported chainId ${nativeAsset.chainId}`);
    }
};
exports.getWrappedToken = getWrappedToken;
const getTokenFromAsset = (asset) => {
    const { symbol, name, precision, assetId } = asset;
    const { chainReference: chainReferenceStr, assetReference } = (0, caip_1.fromAssetId)(assetId);
    const chainReference = Number(chainReferenceStr);
    return new sdk_core_1.Token(chainReference, assetReference, precision, symbol, name);
};
exports.getTokenFromAsset = getTokenFromAsset;
var TradeType;
(function (TradeType) {
    TradeType["LongTailToLongTail"] = "LongTailToLongTail";
    TradeType["LongTailToL1"] = "LongTailToL1";
    TradeType["L1ToLongTail"] = "L1ToLongTail";
    TradeType["L1ToL1"] = "L1ToL1";
})(TradeType || (exports.TradeType = TradeType = {}));
function getTradeType(sellAssetPool, buyAssetPool, sellPoolId, buyPoolId) {
    switch (true) {
        case !!sellAssetPool && !!buyAssetPool:
        case !!buyAssetPool && !sellAssetPool && sellPoolId === 'THOR.RUNE':
        case !!sellAssetPool && !buyAssetPool && buyPoolId === 'THOR.RUNE':
            return TradeType.L1ToL1;
        case sellPoolId === 'THOR.RUNE' && !buyAssetPool:
            return TradeType.L1ToLongTail;
        case !!sellAssetPool && !buyAssetPool:
            return TradeType.L1ToLongTail;
        case !sellAssetPool && !buyAssetPool:
            return TradeType.LongTailToLongTail;
        case !sellAssetPool && !!buyAssetPool:
            return TradeType.LongTailToL1;
        default:
            return undefined;
    }
}
exports.getTradeType = getTradeType;
// TODO: we need to fetch these contracts dynamically, as the whitelist can change
var AggregatorContract;
(function (AggregatorContract) {
    AggregatorContract["TSAggregatorUniswapV3_100"] = "0xbd68cbe6c247e2c3a0e36b8f0e24964914f26ee8";
    AggregatorContract["TSAggregatorUniswapV3_500"] = "0xe4ddca21881bac219af7f217703db0475d2a9f02";
    AggregatorContract["TSAggregatorUniswapV3_3000"] = "0x11733abf0cdb43298f7e949c930188451a9a9ef2";
    AggregatorContract["TSAggregatorUniswapV3_10000"] = "0xb33874810e5395eb49d8bd7e912631db115d5a03";
})(AggregatorContract || (exports.AggregatorContract = AggregatorContract = {}));
exports.contractToFeeAmountMap = {
    [AggregatorContract.TSAggregatorUniswapV3_100]: v3_sdk_1.FeeAmount.LOWEST,
    [AggregatorContract.TSAggregatorUniswapV3_500]: v3_sdk_1.FeeAmount.LOW,
    [AggregatorContract.TSAggregatorUniswapV3_3000]: v3_sdk_1.FeeAmount.MEDIUM,
    [AggregatorContract.TSAggregatorUniswapV3_10000]: v3_sdk_1.FeeAmount.HIGH,
};
exports.feeAmountToContractMap = Object.entries(exports.contractToFeeAmountMap).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
const generateV3PoolAddressesAcrossFeeRange = (factoryAddress, tokenA, tokenB) => {
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
    const poolAddresses = new Map();
    Object.values(v3_sdk_1.FeeAmount)
        .filter((value) => typeof value === 'number')
        .forEach((fee) => {
        const poolAddress = (0, v3_sdk_1.computePoolAddress)({
            factoryAddress,
            tokenA,
            tokenB,
            fee,
        });
        poolAddresses.set((0, viem_1.getAddress)(poolAddress), {
            fee: fee,
            token0Address: (0, viem_1.getAddress)(token0.address),
            token1Address: (0, viem_1.getAddress)(token1.address),
        });
    });
    return poolAddresses;
};
exports.generateV3PoolAddressesAcrossFeeRange = generateV3PoolAddressesAcrossFeeRange;
const getContractDataByPool = (poolAddresses, publicClient, tokenAAddress, tokenBAddress) => {
    const poolContracts = new Map();
    Array.from(poolAddresses.entries()).forEach(([address, { fee, token0Address, token1Address }]) => {
        const poolContract = (0, viem_1.getContract)({
            abi: IUniswapV3PoolAbi_1.IUniswapV3PoolABI,
            address,
            client: publicClient,
        });
        const tokenIn = token0Address === tokenAAddress ? token0Address : token1Address;
        const tokenOut = token1Address === tokenBAddress ? token1Address : token0Address;
        try {
            poolContracts.set(poolContract.address, { fee, tokenIn, tokenOut });
        }
        catch {
            // The pool contract is not supported, that's ok - skip it without logging an error
            return;
        }
    });
    return poolContracts;
};
exports.getContractDataByPool = getContractDataByPool;
const getQuotedAmountOutByPool = async (poolContracts, sellAmount, quoterContract) => {
    const results = await Promise.allSettled(Array.from(poolContracts.entries()).map(async ([poolContract, data]) => {
        const { fee, tokenIn, tokenOut } = data;
        try {
            const params = [tokenIn, tokenOut, fee, sellAmount, BigInt(0)];
            const { result: quotedAmountOut } = await quoterContract.simulate.quoteExactInputSingle(params);
            return [poolContract, quotedAmountOut];
        }
        catch {
            // The pool contract is not supported, that's ok - skip it without logging an error
            return undefined;
        }
    }));
    return new Map(results.reduce((prev, result) => {
        if (result.status !== 'fulfilled' || !result.value)
            return prev;
        return [...prev, result.value];
    }, []));
};
exports.getQuotedAmountOutByPool = getQuotedAmountOutByPool;
const selectBestRate = (quotedAmounts) => {
    return Array.from(quotedAmounts.entries()).reduce((addressWithHighestAmount, [poolAddress, amount]) => {
        if (addressWithHighestAmount[1] === undefined)
            return [poolAddress, amount];
        return amount > addressWithHighestAmount[1] ? [poolAddress, amount] : addressWithHighestAmount;
    }, [undefined, undefined]);
};
exports.selectBestRate = selectBestRate;
exports.shortenedNativeAssetNameByNativeAssetName = {
    'THOR.RUNE': 'r',
    'BTC.BTC': 'b',
    'ETH.ETH': 'e',
    'BNB.BNB': 'n',
    'GAIA.ATOM': 'g',
    'DOGE.DOGE': 'd',
    'LTC.LTC': 'l',
    'BCH.BCH': 'c',
    'AVAX.AVAX': 'a',
    'BSC.BNB': 's',
};
