"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CowNetwork = void 0;
// Most likely non-exhaustive, see https://github.com/cowprotocol/contracts/blob/aaffdc55b2a13738b7c32de96f487d3eb5b4f8c6/src/ts/api.ts#L110
// But we only handle SellAmountDoesNotCoverFee for now so that's fine. Add other errors here as needed.
var CowSwapQuoteErrorType;
(function (CowSwapQuoteErrorType) {
    CowSwapQuoteErrorType["SellAmountDoesNotCoverFee"] = "SellAmountDoesNotCoverFee";
    CowSwapQuoteErrorType["NoLiquidity"] = "NoLiquidity";
})(CowSwapQuoteErrorType || (CowSwapQuoteErrorType = {}));
var CowNetwork;
(function (CowNetwork) {
    CowNetwork["Mainnet"] = "mainnet";
    CowNetwork["Xdai"] = "xdai";
    CowNetwork["ArbitrumOne"] = "arbitrum_one";
})(CowNetwork || (exports.CowNetwork = CowNetwork = {}));
