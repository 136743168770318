"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThorchainChain = void 0;
// When this is updated, also update the instance in generateTradableThorAssetMap
var ThorchainChain;
(function (ThorchainChain) {
    ThorchainChain["BTC"] = "BTC";
    ThorchainChain["DOGE"] = "DOGE";
    ThorchainChain["LTC"] = "LTC";
    ThorchainChain["BCH"] = "BCH";
    ThorchainChain["ETH"] = "ETH";
    ThorchainChain["AVAX"] = "AVAX";
    ThorchainChain["BNB"] = "BNB";
    ThorchainChain["GAIA"] = "GAIA";
    ThorchainChain["THOR"] = "THOR";
    ThorchainChain["BSC"] = "BSC";
})(ThorchainChain || (exports.ThorchainChain = ThorchainChain = {}));
