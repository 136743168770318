"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCowSwapTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_2 = require("../utils/constants");
const cowService_1 = require("../utils/cowService");
const helpers_2 = require("../utils/helpers/helpers");
async function getCowSwapTradeQuote(input, config) {
    const { sellAsset, buyAsset, accountNumber, chainId, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit, potentialAffiliateBps, affiliateBps, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.CowSwap);
    const assertion = (0, helpers_2.assertValidTrade)({
        buyAsset,
        sellAsset,
        supportedChainIds: constants_2.SUPPORTED_CHAIN_IDS,
    });
    if (assertion.isErr())
        return (0, monads_1.Err)(assertion.unwrapErr());
    const buyToken = !(0, helpers_1.isNativeEvmAsset)(buyAsset.assetId)
        ? (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference
        : constants_2.COW_SWAP_NATIVE_ASSET_MARKER_ADDRESS;
    const maybeNetwork = (0, helpers_2.getCowswapNetwork)(chainId);
    if (maybeNetwork.isErr())
        return (0, monads_1.Err)(maybeNetwork.unwrapErr());
    const network = maybeNetwork.unwrap();
    const affiliateAppDataFragment = (0, helpers_2.getAffiliateAppDataFragmentByChainId)({
        affiliateBps,
        chainId: sellAsset.chainId,
    });
    const { appData, appDataHash } = await (0, helpers_2.getFullAppData)(slippageTolerancePercentageDecimal, affiliateAppDataFragment);
    // https://api.cow.fi/docs/#/default/post_api_v1_quote
    const maybeQuoteResponse = await cowService_1.cowService.post(`${config.REACT_APP_COWSWAP_BASE_URL}/${network}/api/v1/quote/`, {
        sellToken: (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference,
        buyToken,
        receiver: receiveAddress,
        validTo: (0, helpers_2.getNowPlusThirtyMinutesTimestamp)(),
        appData,
        appDataHash,
        partiallyFillable: false,
        from: receiveAddress,
        kind: constants_2.ORDER_KIND_SELL,
        sellAmountBeforeFee: sellAmountIncludingProtocolFeesCryptoBaseUnit,
    });
    if (maybeQuoteResponse.isErr()) {
        const err = maybeQuoteResponse.unwrapErr();
        const errData = err.cause?.response?.data;
        if (err.cause?.isAxiosError &&
            errData?.errorType === 'SellAmountDoesNotCoverFee') {
            return (0, monads_1.Err)((0, utils_2.createTradeAmountTooSmallErr)({
                assetId: sellAsset.assetId,
                minAmountCryptoBaseUnit: (0, utils_1.bn)(errData?.data.fee_amount ?? '0x0', 16).toFixed(),
            }));
        }
        return (0, monads_1.Err)(maybeQuoteResponse.unwrapErr());
    }
    const { data } = maybeQuoteResponse.unwrap();
    const { feeAmount: feeAmountInSellTokenCryptoBaseUnit } = data.quote;
    const { rate, buyAmountAfterFeesCryptoBaseUnit, buyAmountBeforeFeesCryptoBaseUnit } = (0, helpers_2.getValuesFromQuoteResponse)({
        buyAsset,
        sellAsset,
        response: data,
        affiliateBps,
    });
    const quote = {
        id: data.id.toString(),
        receiveAddress,
        affiliateBps,
        potentialAffiliateBps,
        rate,
        slippageTolerancePercentageDecimal,
        steps: [
            {
                estimatedExecutionTimeMs: undefined,
                allowanceContract: constants_2.COW_SWAP_VAULT_RELAYER_ADDRESS,
                rate,
                feeData: {
                    networkFeeCryptoBaseUnit: '0',
                    protocolFees: {
                        [sellAsset.assetId]: {
                            amountCryptoBaseUnit: feeAmountInSellTokenCryptoBaseUnit,
                            // Technically does, but we deduct it off the sell amount
                            requiresBalance: false,
                            asset: sellAsset,
                        },
                    },
                },
                sellAmountIncludingProtocolFeesCryptoBaseUnit,
                buyAmountBeforeFeesCryptoBaseUnit,
                buyAmountAfterFeesCryptoBaseUnit,
                source: types_1.SwapperName.CowSwap,
                buyAsset,
                sellAsset,
                accountNumber,
            },
        ],
    };
    return (0, monads_1.Ok)(quote);
}
exports.getCowSwapTradeQuote = getCowSwapTradeQuote;
